import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice:`}</p>
    <p>{`Tabata (8 rounds of :20 on :10 off) Hollow Rocks`}</p>
    <p>{`Double/Triple Under Practice`}</p>
    <p>{`then,  5 Rounds for time of:`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`30-KBS (53/35)`}</p>
    <p>{`20-V Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday is the 4th of July.  We will have our FREE class at
10:00am.  This will be our only class on the 4th, all other classes and
open gym will be cancelled so come out and join in the fun!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      